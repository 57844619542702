<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<div v-show="!viewVisible">
			<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
				<CtmsSearchBoxItem :span="1" label="参加过的项目">
					<Input
						placeholder="请输入参加过的项目"
						v-model="searchParams.projectName"
					/>
				</CtmsSearchBoxItem>
			</CtmsSearchBox>
			<div class="action">
				<Button @click="importSubModule">受试者导入模板</Button>
				<Upload
					v-if="
						!(
							systemActionPermissions.indexOf('btn_subject_projects_add') === -1
						)
					"
					ref="upload"
					:headers="{ token: $store.state.user.token }"
					:show-upload-list="false"
					accept=".xlsx"
					:before-upload="beforeUpload"
					:on-success="onSuccess"
					:on-error="onError"
					:data="extraData"
					:action="`${$baseUrl}/patient-local/patient-records/import`"
					style="display: inline-block"
				>
					<Button>导入数据</Button>
				</Upload>
			</div>
			<CtmsDataGrid
				:height="dataGridHeight"
				:columns="columns"
				:data="listData"
				:page="page"
			>
			</CtmsDataGrid>
		</div>
		<Add
			:visible="addVisible"
			v-show="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:patientId="viewId"
			:record="record"
		>
		</Add>
		<ViewData
			v-show="viewVisible"
			:visible="viewVisible"
			:noEdit="noEdit"
			@onCancel="viewVisible = false"
			@onOk="handleViewOk"
			:viewId="viewId"
		></ViewData>
	</div>
</template>

<script>
import { mapState } from "vuex"
import { formDataToMD5 } from "@/utils/util"
import api from "@/api/subject/projexperience"
import Add from "@/views/Subject/Add.vue"
import ViewData from "@/views/Subject/viewData.vue"

const { getRecordList, apiDelete } = api

export default {
	data() {
		return {
			loading: false,
			columns: [
				{
					title: "筛查号",
					key: "screenNumber",
					minWidth: 100
				},
				{
					title: "姓名",
					key: "name",
					minWidth: 100
				},
				{
					title: "性别",
					key: "sex",
					minWidth: 80
				},
				{
					title: "身份证号",
					key: "idCardNo",
					minWidth: 180
				},
				{
					title: "参加过的项目（附编号）",
					key: "projectName",
					width: 300,
					nowrap: true
				},
				{
					title: "参加筛查时间",
					key: "screenTime",
					minWidth: 180
				},
				{
					title: "是否入组",
					key: "ifIn",
					minWidth: 100
				},
				{
					title: "排除原因",
					key: "exclusReason",
					width: 300,
					nowrap: true
				},
				{
					title: "末次给药时间",
					key: "lastMedicTime",
					minWidth: 180
				},
				{
					title: "出组时间",
					key: "outTime",
					minWidth: 180
				},
				{
					title: "随机号",
					key: "randomNumber",
					minWidth: 80
				},
				{
					title: "联系电话",
					minWidth: 150,
					key: "phone"
				},
				{
					title: "备注",
					key: "remark",
					width: 300,
					nowrap: true
				}
			],
			listData: [],
			actionColumn: [
				{
					title: "操作",
					key: "action",
					width: 200,
					fixed: "right",
					renderButton: () => {
						const actionList = [
							{
								label: "查看详情",
								on: {
									click: this.viewHandle
								}
							},
							{
								label: "修改",
								on: {
									click: this.updateHandle
								}
							},
							{
								label: "删除",
								on: {
									click: this.deleteHandle
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							}
						]
						if (
							this.systemActionPermissions.indexOf(
								"btn_subject_projects_add"
							) === -1
						) {
							actionList[1] = null
							actionList[2] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			addVisible: false,
			viewVisible: false,
			noEdit: false, // false,可编辑 true,不可编辑
			viewId: "",
			record: {},
			searchParams: {
				projectName: ""
			},
			oldSearchParams: {},
			extraData: {
				md5: ""
			},
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	methods: {
		// 进行查重
		checking() {},
		// 文件上传前
		beforeUpload(file) {
			formDataToMD5(file, md5Str => {
				this.extraData.md5 = md5Str
				// 获取md5后进行文件上传
				this.$refs.upload.post(file)
			})
			return false
		},
		// 导入受试者模板
		importSubModule() {
			window.open(`${this.$baseUrl}/patient_template/受试者导入.xlsx`)
		},
		// 文件上传成功后
		onSuccess(res) {
			if (res.code !== 0) {
				this.$Message.error(res.message)
			} else {
				let message = "导入成功"

				if (res.data.addPatientNum) {
					message += `,新增受试者${res.data.addPatientNum}个`
				}
				if (res.data.addPatientRecordNum) {
					message += `,新增受试者项目经历${res.data.addPatientRecordNum}条`
				}
				if (res.data.updatePatientNum) {
					message += `,更新受试者${res.data.updatePatientNum}个`
				}
				if (res.data.updatePatientRecordNum) {
					message += `,更新受试者项目经历${res.data.updatePatientRecordNum}条`
				}
				this.$Message.success(message)
				this.initList()
			}
		},
		onError(error) {
			this.$Message.error(error)
		},
		// 删除受试者
		deleteHandle({ row }) {
			this.$asyncDo(async () => {
				const res = await apiDelete({
					id: row.id
				})
				if (res) {
					this.$Message.success("删除成功")
					this.initList()
				}
			})
		},
		// 导入受试者项目经历
		importRecords() {
			window.open(`${this.$baseUrl}/patient_template/受试者导入.xlsx`)
		},
		// 查看详情
		viewHandle({ row }) {
			this.viewVisible = true
			this.noEdit = true
			this.viewId = row.patientId
		},
		// 查看详情关闭
		handleViewOk() {
			this.viewVisible = false
			this.initList()
		},
		// 修改受试者项目经历成功
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		// 修改受试者项目经历
		updateHandle({ row }) {
			this.addVisible = true
			this.viewId = row.patientId
			this.record = row
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getRecordList({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			delete this.searchParams.projectName
			this.searchHandle()
		},
		searchHandle() {
			this.page.current = 1
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initList()
			})
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	components: {
		Add,
		ViewData
	},
	created() {
		this.columns = [...this.columns, ...this.actionColumn]
		this.initList()
	}
}
</script>

<style lang="less" scoped>
.action {
	height: 50px;
	line-height: 50px;

	button {
		margin-right: 15px;
	}
}
</style>
